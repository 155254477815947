import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../layout';
import SEO from '../seo';

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article className="blog-post container-reading">
        <header className="blog-post-header ">
          <h1 className="blog-post-header-title">{post.frontmatter.title}</h1>
          <p className="blog-post-header-date">{post.frontmatter.date}</p>
          <p className="blog-post-header-description">
            {post.frontmatter.description}
          </p>
        </header>
        <section
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <Link to="/notes">Back to Notes</Link>
      </article>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`;
